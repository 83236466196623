.copyright {
  height: 60px;
  padding: 19px;
  background-color: #026b93;
  text-align: center;
}
.copyright p {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #f6f6f6;
}
.social-icons {
  background-color: #f6f6f6;
  padding: 9px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.footer-links .nav-links {
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}
.footer-links.nav {
  padding: 20px 0px;
}
.footer {
  height: 187px;
  padding-top: 40px;
  border: solid 0.5px rgba(66, 70, 81, 0.2);
  background-color: #fff;
}
