/* styles.css */

.root {
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
}

.root-border {
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  border: solid 0.5px rgba(112, 112, 112, 0.6);
}
.line {
  /* display: flex; */
  background-color: #f44336;
  width: 100%;
  /* height: 100px; */
  margin: 0;
  padding: 0;
}

.body-tag {
  width: 100%;
  display: flex;
  margin: 0px 100px;
  align-items: flex-start;
  /* background-color: rebeccapurple; */
}

.firstRowContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: #f44336; */
}
.firstRowContent .appIcon {
  width: 198px;
  height: 98px;
  margin: 0;
  padding: 0;
}

.naviContent {
  /* justify-content: space-between; */
  /* background-color: #f44336; */
}

.iconAndAuth {
  display: flex;
  width: 30%;
  align-self: center;
  justify-content: space-between;
  /* align-self: flex-end;
  align-items: flex-end;
  align-content: flex-end; */
}
.auth-Content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.diplayTxtContent {
  display: flex;
  /* width: 100%;
  justify-content: space-between; */
}

/* Bottom line styles */
.bottomLineStyle {
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  width: 15%;
}

/* Profile image styles */
.profileImg {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

/* Icon styles */
.Icon {
  width: 30px;
  height: 30px;
}

/* Search icon styles */
.searchIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/* Icon row content styles */
.iconRowContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* background-color: #888; */
}

/* Title text styles */
.titleTxt {
  font-size: 18px;
  font-weight: normal;
  /* font-family: "Poppins"; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424651;
  margin-right: 30px;
  cursor: pointer;
}

/* Modal content styles */
.modalContentStyle {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 300px;
}

/* Button styles */
.buttonStyle {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Logout button styles */
.logoutButtonStyle {
  background-color: #f44336;
  color: white;
}

/* Cancel button styles */
.cancelButtonStyle {
  background-color: #ccc;
  color: black;
}

.content-name {
  text-align: center;
  white-space: pre-wrap;
  font-synthesis: none;
  font-size: 14px;
  /* font-family: "Poppins"; */
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  margin: 0px;
  line-height: 1.5;
  font-stretch: normal;
  font-style: normal;
  margin-right: 30px;
  cursor: pointer;
  color: #f44336;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

@media (max-width: 1000px) {
  .root {
    display: flex;
    background-color: #f6f6f6;
    width: 100%;
  }
  .body-tag {
    width: 100%;
    display: flex;
    /* padding: 14px 50px; */
    flex-direction: column;
    justify-content: space-between;
    /* background-color: #888; */
    margin: 0px;
    padding: 0px;
  }

  .naviContent {
    display: flex;
    justify-content: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .iconAndAuth {
    display: flex;
    /* background-color: darkgoldenrod; */
    width: 100%;
    /* background-color: rgb(99, 30, 210); */
    padding: 10px 100px;
    width: 100%;
    align-self: center;
    justify-content: space-between;
  }

  .iconRowContent {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
    /* background-color: aquamarine; */
  }
  .lastRow {
    display: "flex";

    width: "20%";
  }
  .firstRowContent {
    display: flex;
    align-items: center;
    /* background-color: #f44336; */
    flex-direction: column;
    width: 100%;
  }
  .titleTxt {
    font-size: 14px;
    font-weight: normal;
    /* font-family: "Poppins"; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #424651;
    margin-right: 20px;
    cursor: pointer;
  }

  .contentName {
    /* font-family: Poppins; */
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #424651;
    text-align: center;
    /* background-color: aqua; */
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .profileImg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
@media (max-width: 500px) {
  .root {
    display: flex;
    background-color: #f6f6f6;
    /* justify-content: space-between; */
    /* background-color: #f44336; */
    width: 100%;
  }
  .body-tag {
    width: 100%;
    display: flex;
    /* padding: 14px 50px; */
    flex-direction: column;
    justify-content: space-between;
    /* background-color: #888; */
    margin: 0px;
    padding: 0px;
  }
  .iconRowContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px 0px;
    /* background-color: #f44336; */
  }

  .naviContent {
    width: 100%;
    justify-content: space-between;
    /* background-color: #f44336; */
  }
  .diplayTxtContent {
    display: flex;
    width: 100%;
    background-color: #f44336;
    justify-content: space-between;
    /* padding: 10px 0px; */
  }

  .contentName {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #424651;
    margin-left: 50px;
  }

  .firstRowContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #888; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
  }
}
