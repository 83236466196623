.full-container-body {
  display: flex;
  height: 100%;
  /* background-color: orange; */
}

.background-image-show {
  flex: 1;
  background-image: url("../../../assets/Images/sea_view_login.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: #dddddd;
  background-color: #f6f6f6;
  border-style: solid;
  flex: 1;
  width: 100%;
  /* background-color: antiquewhite; */
}

.display-app-icon {
  width: 198px;
  height: 98px;
  margin: 0px;
}

.field-content {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.text-field-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 67%;
  /* background-color: aquamarine; */
}

.email-icon {
  width: 20px;
  height: 20px;
  opacity: 0.4;
}

.text-field-box .text-style {
  font-size: 16;
  /* font-family: Poppins; */
  color: #424651;
  border-bottom: none;
  background-color: #fff;
  height: 40px;
}

.err-margin {
  margin: -7px 0px 0px 0px;
  /* background-color: #026b93; */
}
.err-margin .error-msg-txt {
  color: #dc143c;
  font-size: 12px;
  /* font-family: Poppins; */
}

.unChecked {
  border: 1px solid rgba(66, 70, 81, 0.4);
  border-radius: 1px;
  width: 22px;
  height: 22px;
}

.checked {
  color: #424651;
  border: 1px solid rgba(66, 70, 81, 0.4);
  border-radius: 1px;
  width: 22px;
  height: 22px;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

.end-content .login-btn-style {
  margin-top: 30px;
  width: 100%;
  background-color: #026b93;
  color: white;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: bold;
  text-transform: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  padding: 10px;
}

.forgot-pwd-div {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
  /* background-color: #dc143c; */
}

.forgot-pwd-div .forgot-pwd-txt {
  font-size: 14px;
  /* font-family: Poppins; */
  align-self: flex-start;
  color: rgba(66, 70, 81, 0.87);
  cursor: pointer;
}

.forgot-pwd-div .no-acc-txt {
  font-size: 14px;
  /* font-family: Poppins; */
  align-self: flex-end;
  text-align: end;
  color: rgba(66, 70, 81, 0.87);
  cursor: pointer;
}

@media (max-width: 767px) {
  .full-container-body {
    display: flex;
    height: 150vh;
  }

  .background-image-show {
    flex: 0;
  }

  .display-app-icon {
    width: 198px;
    height: 98px;
    margin: 0px;
    /* background-color: dimgray; */
  }

  .form-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-color: #dddddd;
    background-color: #f6f6f6;
    border-style: solid;
    flex: 1;
    width: 100%;
  }
  .field-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 60px;
    /* background-color: antiquewhite; */
  }

  .text-field-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;
    /* background-color: aquamarine; */
  }

  .text-field-box .text-style {
    font-size: 14;
    /* font-family: Poppins; */
    color: #424651;
    border-bottom: none;
    background-color: #fff;
    height: 40px;
  }

  .end-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;
  }
}

@media (orientation: landscape){

  /* .full-container-body{
    height: 200%;
  } */

  /* .text-field-box{
     width: 100px; 
  } */

  .form-style{
    overflow-y: auto;
    height: auto;
    overflow-x: hidden;
  }
}

@media (max-height: 575.98px) and (orientation: landscape){
  .full-container-body{
    height: 200%;
  }
}