.card-content {
  width: 375px;
}

.boat-img {
  height: 251px;
}

.boat-name-div {
  /* background-color: aquamarine; */
  /* align-self: flex-start; */
  /* align-items: flex-start; */
  /* align-content: flex-start; */
  /* justify-content: flex-start; */
  display: flex;
  /* flex: none; */
  padding: 0px;
  /* margin: 0px; */
}

.boat-name-div .boat-name {
  text-align: left;
  /* white-space: pre-wrap; */
  /* margin: 0; */
  font-synthesis: none;
  font-style: bold;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: normal;
  text-transform: none;
  padding: 0px;
  margin: 0px;
  color: #424651;
  line-height: 1.5;
}

.city-name-content {
  display: flex;
}

.city-name-content .city-name {
  text-align: left;
  font-synthesis: none;
  /* font-style: bold; */
  /* font-weight: 700; */
  font-size: 26px;
  /* letter-spacing: normal; */
  /* text-transform: none; */
  padding: 0px;
  margin: 0px;
  color: rgba(66, 70, 81, 0.6);
  white-space: nowrap;
  /* line-height: 1.5; */
}

.money-icon-style {
  width: 30px;
  height: 30px;
  align-self: center;
  align-content: center;
  align-items: center;
}

.group-icon {
  width: 43px;
  height: 30px;
  align-self: center;
  align-content: center;
  align-items: center;
}

.momey-div {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
  justify-content: space-between;
  /* align-content: flex-start; */
  align-items: center;
  /* align-self: flex-start; */
  /* background-color: lightgoldenrodyellow; */
  /* margin: 0px;
  padding: 0px 10px; */
  padding-top: 10px;
}

.momey-div .price-txt {
  text-align: center;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  color: #424651;
  /* color: black; */
  /* opacity: 0.8; */
  white-space: nowrap;
  /* margin: 0px 0px 0px 15px; */
  /* background-color: blue; */
}

.momey-div .capacity-txt {
  text-align: center;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 0px 15px;
}

.end-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-content: space-between;

  /* background-color: aqua; */
}
/* .end-details-item { */
  /* background-color: aquamarine; */
  /* padding-bottom: 15px;
  padding-left: 22px; */
/* } */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
@media (max-width: 767px) {
  .boat-img {
    height: 150px;
  }

  .boat-name-content .boat-name {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    font-style: bold;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: normal;
    text-transform: none;
    padding: 0px;
    margin: 0px;
    color: #424651;
    line-height: 1.5;
  }

  .city-name-content .city-name {
    text-align: left;
    font-synthesis: none;
    /* font-style: bold; */
    /* font-weight: 700; */
    font-size: 14px;
    letter-spacing: normal;
    text-transform: none;
    padding: 0px;
    margin: 0px;
    color: rgba(66, 70, 81, 0.6);
    line-height: 1.5;
  }

  .money-icon-style {
    width: 30px;
    height: 20px;
    /* align-self: center;
    align-content: center;
    align-items: center; */
  }

  .group-icon {
    width: 29px;
    height: 20px;
    align-self: center;
    align-content: center;
    align-items: center;
  }

  .momey-div .price-txt {
    text-align: center;
    align-self: center;
    font-size: 14px;
    font-weight: 600;
    /* width: 100%; */
    /* margin: 0px 0px 0px 15px; */
  }

  .momey-div .capacity-txt {
    text-align: center;
    align-self: center;
    font-size: 14px;
    /* font-weight: 600; */
    margin: 0px 0px 0px 10%;
  }

  /* .end-content { */
    /* display: flex;
    flex-direction: row;
    width: 100%; */
    /* align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-content: space-between;
    margin: 0;
    padding: 0px; */
  /* } */
  /* .end-details-item { */
    /* padding: 1%; */
  /* } */
}
