.navigation-container-box {
  display: flex;
  flex: 1;
  padding-left: 140px;
  padding-right: 140px;
  width: 100%;
}

.header-container-style {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* width: 100%; */
  padding: 0px;
  /* justify-content: space-around; */
  /* background-color: antiquewhite; */
}

.drawer-menu-icon .menu-icon {
  display: none;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
}

.all-content-box {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  flex: 1;
  /* background-color: red; */
  justify-content: space-between;
}

.icon-tabName-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* background-color: gray; */
}

.icon-tabName-content .app-icon-style {
  width: 198px;
  height: 98px;
  margin: 0px;
  /* background-color: aliceblue; */
}

.tab-name-list {
  /* background-color: lightpink; */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  padding: 10px 0;
}

.tab-name-list .tab-name-txt {
  margin-left: 25px;
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
  /* background-color: aqua; */
}

/*  */
/*  */
/*  */
/* .drawer-styles { */
  /* background-color: lightgreen; */
/* } */

.app-icon-drawer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  align-content: center;
  /* background-color: lightgreen; */
}
.app-icon-drawer .drawer-app-icon-style {
  width: 98px;
  height: 58px;
  margin: 0px;
  /* background-color: antiquewhite; */
}

.content-list {
  display: flex;
  flex-direction: column;
  margin: 0px 24px;
}

.content-list .straight-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 1.5px;
  border: 1px;
  width: auto;
  background-color: #acacac66;
  margin: 0 30px;
}

.icon-group {
  display: flex;
  align-items: center;
  /* background-color: aliceblue; */
}

.icon-group .search-mail-icon {
  width: 25px;
  height: 25px;
  margin-right: 25px;
  cursor: pointer;
}

.icon-group .bell-icon {
  width: 25px;
  height: 25px;
  margin-right: 25px;
  cursor: pointer;
}

.end-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.end-content .straight-line2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 1.5px;
  border: 1px;
  width: 90%;
  background-color: #acacac66;
  margin: 0 30px;
}

@media (max-width: 1080px) {
  .navigation-container-box {
    display: flex;
    flex: 1;
    padding-left: 100px;
    padding-right: 100px;
    /* background-color: blueviolet; */
    /* width: 100%; */
  }

  /* .header-container-style {
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  } */

  .header-container-style {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    flex: 1;
    justify-content: space-around;
    background-color: white;
    /* background-color: red; */
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    /* background-color: darksalmon; */
  }

  .drawer-menu-icon .menu-icon {
    display: flex;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0);
  }

  .drawer-menu-icon {
    display: flex;
    align-self: center;
  }

  .tab-name-list {
    display: none;
    flex-direction: row;
    width: 100%;
    align-self: center;
  }

  .icon-tabName-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: flex-start;
    padding-left: 24px;
  }
}

@media (max-width: 862px) {
  .navigation-container-box {
    display: flex;
    flex: 1;
    padding-left: 50px;
    padding-right: 50px;
    /* background-color: blueviolet; */
    /* width: 100%; */
  }
  /* .header-container-style {
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  } */
}

/* ================================================================================ */
/* ================================================================================ */
/* ================================================================================ */
/* ================================================================================    max-width: 467px */
/* ================================================================================ */
/* ================================================================================ */
/* ================================================================================ */
@media (max-width: 910px) {
  .navigation-container-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    background-color: white;
    padding-left: 0%;
    padding-right: 0%;
  }
  
  .drawer-menu-icon {
    display: flex;
    align-self: center;
  }

  .icon-tabName-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: flex-start;
    padding-left: 24px;
  }
  .icon-tabName-content .app-icon-style {
    width: 120px;
    height: 58px;
    margin: 0px;
    /* background-color: aqua; */
  }

  /*  */
  /*  */
  /*  */
  /*  */
  /*  */
  /*  */
  .icon-group .search-mail-icon {
    width: 20px;
    height: 20px;
    margin-right: 25px;
  }
  .icon-group .bell-icon {
    width: 25px;
    height: 25px;
    margin-right: 0;
    /* background-color: aqua; */
  }
  .icon-group .profile-icon {
    display: none;
    width: 45px;
    height: 45px;
    opacity: 5;
  }
}
