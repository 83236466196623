.full-box {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 100px;
}

.icon-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.top-back-button {
  display: none;
}
.app-icon-style {
  margin-left: 140px;
  width: 200px;
  height: 100px;
}

.back-icon {
  width: 14px;
  height: 18px;
}

.box-body {
  display: flex;
  height: 90%;
}

.container-body {
  background-color: white;
  display: flex;
  justify-content: center;
  height: 100%;
}

.container-inside-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.back-content {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  align-self: flex-start;
  margin-left: 10px;
}

.back-content .back-txt {
  font-size: 20px;
  /* font-family: Poppins; */
  font-weight: normal;
  color: #3973a5;
  margin-left: 16px;
}

.inside-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 0px;
  padding: 0px;
}

.container-inside-box .inside-body .page-title-txt {
  font-size: 40px;
  /* font-family: Poppins; */
  font-weight: bold;
  color: black;
  text-align: center;
}

.container-inside-box .inside-body .page-info-txt {
  font-size: 24px;
  /* font-family: Poppins; */
  font-weight: bolder;
  color: black;
  text-align: center;
}
.text-filed-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
}

.pwd-filed {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 50%;
  margin-top: 20px;
}

.pwd-filed .input-txt {
  font-size: 16px;
  /* font-family: Poppins; */
  color: #424651;
  padding: 10px;
  border-bottom: none;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 15px;
  height: 30px;
}

.pwd-strength-div {
  border-color: white;
  border-radius: 5px;
  border-width: 1;
  border-style: solid;
  align-self: center;
  flex: 0.5;
  background-color: white;
  position: absolute;
  right: 4%;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  width: 100%;
  max-width: 13%;
  height: auto;
  margin-top: 10px;
  border: 1px solid rgba(66, 70, 81, 0.4);
}

.container-inside-box .inside-body .submit-btn {
  color: white;
  text-align: center;
  border-radius: 15px;
  font-size: 24px;
  padding: 10px 100px;
  margin: 50px 100px;
  background-color: #3973a5;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/* ==================== width: 467px ==================== */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

@media (max-width: 467px) {
  .full-box {
    width: 100%;
    height: 100vh;
    background-color: white;
    padding-bottom: 100px;
  }

  .icon-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    /* background-color: lightcoral; */
    /* padding: 10px; */
  }

  .app-icon-style {
    /* margin: 0px; */
    width: 120px;
    height: 58px;
    margin: 0px 0px 0px 40px;
    /* background-color: lightcoral; */
  }

  .top-back-button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    align-content: center;
    margin: 20px 0px 0px 0px;
    /* background-color: red; */
  }
  .back-content {
    display: none;
  }

  .container-inside-box .inside-body .page-title-txt {
    font-size: 25px;
    /* font-family: Poppins; */
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .container-inside-box .inside-body .page-info-txt {
    font-size: 14px;
    /* font-family: Poppins; */
    font-weight: bolder;
    color: black;
    text-align: center;
  }

  .pwd-filed {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    /* background-color: lightcoral; */
  }
  .container-inside-box .inside-body .submit-btn {
    color: white;
    text-align: center;
    border-radius: 15px;
    font-size: 18px;
    padding: 10px 50px;
    margin: 30px 50px;
    background-color: #3973a5;
  }
}
