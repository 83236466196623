.booking-history-container {
  display: flex;
  width: 100%;
  /* height: 100vh; */
  /* min-height: 100vh; */
  /* max-height: 100%; */
   min-height: 100%;
   /* height: 100%; */
  flex-direction: column;
  background-color: #f6f6f6;
}

.container{
  width: "100%";
  min-height: "100%"; 
  margin-top: "3%";
}

.container .page-title {
  /* font-family: Poppins; */
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

.container .info-text {
  margin-top: 24px;
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: rgba(66, 70, 81, 0.38);
}

.select-tab {
  margin-top: 74px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inside-select-tab {
  width: 50%;
  border: solid 0.5px rgba(66, 70, 81, 0.2);
  background-color: white;
}

.select-tab .tab-text {
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
  padding: 16px 0px;
  text-align: center;
  /* width: 50%; */
  border: solid 1px rgba(66, 70, 81, 0.2);
}

.booking-row {
  margin-top: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Add other styles as needed */
  background-color: white;
  padding: 48px 81px;
  margin-bottom: 3%;
  border: solid 1px rgba(66, 70, 81, 0.2);
}

.cancel_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* background-color: #026b93; */
  padding: 2px 10px;
  text-align: center;
  /* color: white; */
  border-radius: 2px;
  font-weight: 600
}

.booking-row .booking-date {
  /* font-family: Poppins; */
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  /* text-align: left; */
  color: #424651;
  /* white-space: nowrap; */
}

.booking-row .booking-name-txt {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  /* text-align: center; */
  color: rgba(66, 70, 81, 0.38);
}

.boat-name-content {
  display: flex;
  flex-direction: column;
  inline-size: min-content;
  white-space: nowrap;
  padding-left: 10%;
}

.down_arrow{
  position: relative;
}

.down_arrow_dropdown{
  position: absolute;
  background-color: white;
  width: '50%';
  padding: 20px;
  border-radius: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 768px){
  .container .page-title{
    font-size: 30px;
    margin-top: 10px;
  }
  .container .info-text{
    font-size: 18px;
    margin-top: 14px;
  }
  .booking-row {
    padding: 28px 41px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 600px){
  .select-tab{
    display: none;
  }
  .container .page-title{
    font-size: 25px;
  }
  .container .info-text{
    font-size: 14px;
  }
  .booking-row .booking-date{
    font-size: 17px;
  }
  .down_arrow{
    display: flex;
    width: 15px;
    height: 15px;
    /* margin-top: 15px; */
  }
  .booking-row{
    padding: 18px 20px;
    margin: 20px 0px;
  }
}