.show-modal-box {
  display: flex;
  justify-content: center;
  /* background-color: aqua; */
}

.pac-container {
  z-index: 2000 !important;
}

.modal-style {
  width: 15%;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 10px;
  background-color: white;
  /* align-self: center; */
  justify-content: center;
  /* align-content: center; */
  /* align-items: center; */
}

.modal-options {
  font-size: 16;
  /* font-family: Poppins; */
  color: #424651;
  cursor: pointer;
  text-align: center;
}
