.setting {
  display: flex;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  /* background-color: aqua; */
  /* justify-content: space-between; */
  background-color: #f6f6f6;
}

.footer-style {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* align-self: flex-end; */
  /* align-content: flex-end; */
  justify-content: flex-end;
  /* align-items: flex-end; */
}

.setting .title {
  /* width: 170px; */
  /* height: 56px; */
  /* margin: 100px 25.5px 45px 281.5px; */
  /* font-family: Poppins; */
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
  margin-top: 50px;
}

.inside-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header-tab {
  padding: 45px 0px 0px 0px;
  /* background-color: bisque; */
}

.header-tab .tab-title {
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: black;
  padding: 0px 30px;
  /* background-color: aquamarine; */
}

.header-tab .tab-selected-title {
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #3973a5;
  text-decoration: underline;
  padding: 0px 30px;
  /* background-color: #3973a5; */
}

/* =================================================>>>>>>>>>>>>>>>>>>>>>>>>       Notification tab       */
.notification {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
}

.notification .notify-list-title-txt {
  /* font-family: Poppins; */
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

.notify-content-name-txt {
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
  /* background-color: #3973a5; */
}

.notify-empty-check-box {
  width: 28px;
  height: 28px;
  margin: 0 28px 0 0;
  border-radius: 10px;
  border: solid 0.5px #424651;
}

.notify-tick-check-box {
  width: 28px;
  height: 28px;
  margin: 0 28px 0 0;
  padding: 7px 3.9px 6px 4px;
  border-radius: 10px;
  border: solid 0.5px #424651;
  color: black;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 22px;
}

/* =================================================>>>>>>>>>>>>>>>>>>>>>>>>       SecurityAndPwd  tab       */
.security-pwd-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  height: 100%;
  /* border: solid 0.5px rgba(66, 70, 81, 0.5); */
}

.security-pwd {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  border: solid 0.5px rgba(66, 70, 81, 0.5);
}

.tick-background-design {
  background-color: #3973a5;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.security-pwd .security-pwd-title-txt {
  /* font-family: Poppins; */
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

.security-pwd .pwd-second-title {
  /* width: 230px; */
  /* height: 28px; */
  margin: 0px 0px 0px 21px;
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

.security-pwd .sub-title-info {
  /* width: 458px; */
  /* height: 20px; */
  margin: 11px 0 0 0;
  /* font-family: Poppins; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

/* ===============================================>>>>>>>>>>>>>>>>>>>>>>>>   change password  tab       */
.modal-content .change-pwd-txt {
  /* font-family: Poppins; */
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
  text-align: center;
  margin: 44px 0px;
}

.modal-content .text-field-inputs {
  font-size: 16px;
  /* font-family: Poppins; */
  color: #424651;
  padding: 10px;
  border-bottom: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  height: 25px;
  border: solid 1px rgba(66, 70, 81, 0.2);
  margin-top: 10px;
}

.modal-content .text-field-label {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}
