.google-box {
  width: 100%;
  height: auto;
}

.google-box .google-login-btn {
  width: 100%;
  text-transform: none;
  border: 1.5px solid #026b93;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  padding: 10px;
}

.google-box .google-login-btn .google-img {
  width: 20px;
  height: 20px;
  fill: #3973a5;
  color: #3973a5;
  margin-right: 15px;
}

.google-box .btn-name {
  color: #026b93;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: bold;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*                             max-width: 467px                               */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
@media (max-width: 467px) {
  .google-box .google-login-btn .google-img {
    width: 15px;
    height: 15px;
    fill: #3973a5;
    color: #3973a5;
    margin-right: 15px;
  }

  .google-box .btn-name {
    color: #026b93;
    font-size: 10px;
    /* font-family: Poppins; */
    font-weight: bold;
  }
}
