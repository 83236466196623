@keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
  }
  .animate-flicker {
    max-width: 50%;
    height: auto;
    margin: 5px;
    border-radius: 2px;
    opacity:1;  
    animation: flickerAnimation 1s infinite;
  }
  