.full-box {
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  background-color: #f6f6f6;
  padding: 0px;
  margin: 0px;
}

.formStyle {
  width: 100%;
  height: 100%;
  /* height: auto; */
  background-color: #f6f6f6;
  padding: 0px;
  margin: 0px;
}

.appIconStyle {
  margin-left: 140px;
  width: 200px;
  height: 100px;
}

.body-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0px 10px;
  margin: 0px;
  height: 100%;
  /* width: 100%; */
}

.body-content .page-title-gj {
  font-size: 45px;
  /* font-family: Poppins; */
  line-height: 0.89;
  color: #424651;
  font-weight: bold;
  padding: 0px;
  text-align: center;
}

.body-content .title-info {
  margin-top: 10px;
  font-size: 30px;
  /* font-family: Poppins; */
  line-height: 1.33;
  font-weight: 500;
  font-size: normal;
  text-align: center;
  color: #424651;
}

.login-div {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  align-self: center;
  text-align: center;
}

.body-content .end-content .login-div .acc-txt {
  color: #424651;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: normal;
  text-align: center;
}

.body-content .end-content .login-div .login-txt {
  margin-left: 40px;
  color: #424651;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: normal;
  text-decoration: underline;
  text-align: center;
}

/* Styles for the checkbox checked state */
.checkBoxcheckedstyle {
  color: #424651;
  border: 1px solid rgba(66, 70, 81, 0.4);
  border-radius: 1px;
  width: 22px;
  height: 22px;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

.first-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
  /* margin: 40px 0px 0px 0px; */
  padding: 0px;
  /* background-color: aquamarine; */
}

.first-field .text-fileds {
  margin: 0px;
  border-radius: 5px;
  width: 45%;
}

.body-content .first-field .text-field-inputs {
  font-size: 16px;
  /* font-family: Poppins; */
  color: #424651;
  padding: 10px;
  border-bottom: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  height: 25px;
}

.custom-placeholder::placeholder {
  color: blue; /* Change the color to your desired style */
  /* Add any other styles you want for the placeholder */
}

.body-content .text-filed {
  margin: 0px;
  border-radius: 5px;
  width: 55%;
  margin-top: 20px;
}

.body-content .text-inputs {
  font-size: 16px;
  /* font-family: Poppins; */
  color: #424651;
  /* padding: 10px; */
  border-bottom: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  height: 25px;
}

.body-content .phone-no {
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  padding: 0px 0px;
}

.country-div {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 5px;
}

/* .makeStyles-flag_size_style-15{
  padding-right: 7px;
} */

.country-div .flag-design {
  font-size: 30px;
  text-align: center;
} 

.country-div .dial-code-txt {
  font-size: 16px;
  text-align: center;
  margin-left: 20px;
  /* font-family: Poppins; */
  align-self: center;
  color: #424651;
}

.modal-county-txt {
  margin-right: 15px;
  font-size: 20px;
}

.pwd-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  max-width: 55%;
  margin: 0 auto;
}

.end-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
}

.body-content .end-content .btnStyle {
  width: 100%;
  background-color: #026b93;
  color: white;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: bold;
  text-transform: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  padding: 10px;
}
.body-content .end-content .btnStyle:hover {
  background-color: #025977;
}

.error-msg-txt {
  color: #dc143c;
  font-size: 8;
  /* font-family: Poppins; */
  margin-top: 5px;
  /* background-color: lightseagreen; */
}

.pwd-filed {
  display: flex;
  align-self: center;
  align-items: center;
  align-content: center;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin: 20px 0px 0px 0px;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  /* background-color: #025977; */
}

.pwd-filed .pwd-txt-input {
  font-size: 16px;
  /* font-family: Poppins; */
  color: #424651;
  border-bottom: none;
  background-color: #fff;
  padding: 0px 0px 0px 15px;
  margin: 0;
  height: 100%;
}

.check-box-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.unChecked-box {
  border: 1px solid rgba(66, 70, 81, 0.4);
  border-radius: 1px;
  width: 22px;
  height: 22px;
}

.checked-box {
  color: #424651;
  border: 1px solid rgba(66, 70, 81, 0.4);
  border-radius: 1px;
  width: 22px;
  height: 22px;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

.body-content .check-box-div .check-box-txt {
  font-size: 14px;
  /* font-family: Poppins; */
  color: rgba(66, 70, 81, 0.87);
  font-weight: 400;
  line-height: 15px;
}

.err-term-service-txt {
  color: #dc143c;
  font-size: 12px;
  /* font-family: Poppins; */
  margin-top: -10px;
  /* background-color: aquamarine; */
}

.pwd-strength-div {
  border-color: white;
  border-radius: 5px;
  border-width: 1;
  border-style: solid;
  align-self: center;
  flex: 0.5;
  background-color: white;
  position: absolute;
  right: 4%;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);

  height: auto;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  width: 20%;
}
.pwd-strength-title {
  color: #424651;
  font-size: 14px;
  /* font-family: Poppins; */
  font-weight: revert;
}

.pwd-strength-info-marker {
  height: 5px;
  width: 100%;
  border: solid 0.5px rgba(66, 70, 81, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: #025977; */
}

.pwd-strength-info-txt {
  color: #424651;
  font-size: 12px;
  /* font-family: Poppins; */
  font-weight: normal;
  letter-spacing: 0;
  /* background-color: #dc143c; */
}

.small-pwd-strength-div {
  display: none;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*                             max-width: 467px                               */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
@media (max-width: 767px) {
  .appIconStyle {
    margin: 0px;
    width: 200px;
    height: 100px;
  }
  .formStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    padding: 0px;
    margin: 0px;
  }

  .body-content {
    background-color: #f6f6f6;
  }
  .body-content .page-title-gj {
    font-size: 16px;
    /* font-family: Poppins; */
    line-height: 24px;
    color: #424651;
    font-weight: bold;
    padding: 0px;
    text-align: center;
    /* background-color: #025977; */
  }

  .body-content .title-info {
    margin-top: 10px;
    font-size: 12px;
    /* font-family: Poppins; */
    line-height: 18px;
    font-weight: 500;
    font-size: normal;
    text-align: center;
    color: #424651;
  }

  .pwd-strength-div {
    display: none;
  }

  .small-pwd-strength-div {
    display: flex;
    /* background-color: lightcyan; */
    margin-bottom: 12px;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    align-content: flex-start;
  }

  .small-pwd-strength-div .small-pwd-strength-err-info {
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 400;
    color: #ef3a3a;
    text-align: start;
  }

  .small-pwd-strength-div .small-pwd-strength-success-info {
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 400;
    color: green;
  }

  .body-content .first-field .text-field-inputs {
    font-size: 10px;
    /* font-family: Poppins; */
    color: #424651;
    padding: 8px 10px;
    border-bottom: none;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
    height: 20px;
    /* background-color: lightgreen; */
  }

  .body-content .text-inputs {
    font-size: 10px;
    /* font-family: Poppins; */
    color: #424651;
    padding: 8px 10px;
    border-bottom: none;
    border-radius: 5px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
    background-color: white;
    height: 20px;
    /* background-color: lightblue; */
  }

  .pwd-filed {
    display: flex;
    align-self: center;
    align-items: center;
    align-content: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    margin: 20px 0px 0px 0px;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
  }

  .pwd-filed .pwd-txt-input {
    font-size: 10px;
    /* font-family: Poppins; */
    color: #424651;
    border-bottom: none;
    background-color: #fff;
    padding: 0px 0px 0px 15px;
    margin: 0;
    height: 100%;
  }

  .check-box-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .body-content .check-box-div .check-box-txt {
    font-size: 10px;
    /* font-family: Poppins; */
    color: rgba(66, 70, 81, 0.87);
    font-weight: 400;
    line-height: 15px;
  }

  .body-content .end-content .btnStyle {
    width: 100%;
    background-color: #026b93;
    color: white;
    font-size: 10px;
    /* font-family: Poppins; */
    font-weight: bold;
    text-transform: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.09);
    padding: 10px;
  }
  .body-content .end-content .login-div .acc-txt {
    color: #424651;
    font-size: 10px;
    /* font-family: Poppins; */
    font-weight: normal;
    text-align: center;
  }
  .body-content .end-content .login-div .login-txt {
    margin-left: 40px;
    color: #424651;
    font-size: 10px;
    /* font-family: Poppins; */
    font-weight: normal;
    text-decoration: underline;
    text-align: center;
  }
  .country-div {
    cursor: pointer;
    justify-content: center;
    display: flex;
    padding-left: 20px;
    padding-right: 5px;
  }

  .country-div .flag-design {
    font-size: 20px;
    text-align: center;
  }

  .country-div .dial-code-txt {
    font-size: 10px;
    text-align: center;
    margin-left: 15px;
    /* font-family: Poppins; */
    align-self: center;
    color: #424651;
  }
}
