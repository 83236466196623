/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

input, textarea {
    touch-action: manipulation;
    user-scalable: no;
  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-style: regular; */
  font-weight: none;
  /* font-weight: 400; */
  /* .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  } */
}