.container-style {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */
}

.heading .heading-text {
  font-size: 45px;
  /* font-family: "Poppins"; */
  color: #424651;
  font-weight: 500;
  text-align: center;
}

.form-container {
  margin-top: 40px;
  background-color: #fff;
  align-self: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 2px;
  margin-bottom: 40px;
  width: 70%;
}

.item-container {
  display: flex;
  width: 45%; /* Adjust this value as needed */
}

.step-container {
  display: flex;
  flex-direction: column;
}

.step-container .step-number {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.51;
  /* font-family: "Poppins"; */
  color: #424651;
  padding-top: 20px;
  padding-bottom: 20px;
}

.divider {
  height: 0.5px;
  background-color: rgba(66, 70, 81, 0.2);
  margin-left: -7%;
  margin-right: -7%;
}

.document-section {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.document-section .document-section-heading {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.53;
  /* font-family: "Poppins"; */
  color: #424651;
}

.textbox-area {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-bottom: "none";
  margin-top: 15px;
  border-radius: 5px;
  width: 100%;
}

.err-msg-text {
  font-size: 18px;
  /* font-family: "Poppins"; */
  color: red;
  text-align: center;
}

.document-section .boat-avaliability {
  font-size: 30px;
  font-weight: "normal";
  line-height: 1.53;
  /* font-family: Poppins; */
  color: #424651;
  margin-top: 80px;
}

.calendar-timeSlot-container {
  width: 100%;
  /* background-color: rebeccapurple; */
}

.calendar-timeSlot-container-inner {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  /* background-color: #3973a5; */
  width: 100%;
}

.calendar-container {
  display: flex;
  flex: 0.5;
  justify-content: center;
  width: 100%;
  /* background-color: gray; */
}

.timeSlot-container {
  /* background-color: lightseagreen; */
  /* flex: 0.5; */
  width: 50%;
  /* background-color: #3973a5; */
}

.show-selected-time-list {
  height: 250px; /* Set the maximum height to 500px */
  overflow: auto; /* Enable scrolling if content exceeds the height */
  position: relative;
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.flex-style {
  display: flex;
  flex-wrap: wrap;
  /* align-self: center; */
  /* justify-content: center; */
  /* align-items: center; */
  /* align-items: flex-start; */
}

/* Styling for the save continue button */
.save-continue-button {
  margin-top: 60px;
  align-self: flex-end;
  margin-bottom: 70px;
}

/* Styling for the time text */
.time-text {
  cursor: pointer;
  letter-spacing: 0.5px;
  padding: 5px;
  font-size: 14px;
}

/* Styling for the save continue button text */
.save-continue-button-text {
  font-size: 20px;
  text-transform: none;
  background-color: #3973a5;
  /* font-family: "Poppins"; */
  color: #fff;
  font-weight: normal;
  line-height: 1.5;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 40px;
  padding-right: 40px;
}

/* Styling for the password input */
.pwd-styles {
  font-size: 16px;
  /* font-family: "Poppins"; */
  color: #424651;
  border-bottom: none;
  background-color: #fff;
  border-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

/* Styling for the error message text */

/* Styling for the text field */
.text-field-styles {
  padding-left: 20px;
  /* Add more styles here if needed */
}

.timeSlot-container .start-at-text {
  margin-top: 25px;
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}

.selected-date {
  /* font-family: Poppins; */
  color: #424651;
  font-size: 22;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .calendar-timeSlot-container-inner {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    /* align-content: center; */
    /* background-color: #3973a5; */
  }

  .timeSlot-container {
    /* background-color: lightseagreen; */
    /* flex: 0.5; */
    width: 100%;
    /* background-color: #3973a5; */
  }

  .show-selected-time-list {
    display: flex;
    height: 250px; /* Set the maximum height to 500px */
    overflow: auto; /* Enable scrolling if content exceeds the height */
    position: relative;
    /* display: flex; */
    /* flex-wrap: wrap; */
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  .flex-style {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 700px) {
  .flex-style {
    display: flex;
    flex-wrap: wrap;
    /* align-self: center; */
    /* align-items: center; */
  }
}

@media (max-width: 470px) {
  .flex-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-self: center; */
    /* align-items: center; */
  }
}
