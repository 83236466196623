.policy {
  margin: 15px 0 0.3px;
  /* padding: 20px; */
  border-radius: 15px;
  border: solid 1px rgba(66, 70, 81, 0.2);
  background-color: #fff;
}
.vl {
  border-right: solid 1px rgba(66, 70, 81, 0.2);
}
:is(.policy_text, .policy_id) {
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #424651;
}
.policy_statement_id {
  width: 8%;
  padding: 20px;
}
.policy_statement {
  width: 92%;
  padding: 20px;
}
